import React, { useEffect } from 'react';

const TermsOfService = () => {
  const redirectToPage = () => {
    window.location.replace('https://legal.justt.me/TermsOfService_de.html');
  };

  useEffect(() => {
    redirectToPage();
  }, []);

  return <div />;
};

export default TermsOfService;
